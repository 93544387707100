// background-color: #08a5a8;
// color: #e9feff;

* {
    margin: 0;
    padding: 0;
    font-family: Nunito Sans;
    text-decoration: none;

}

::-webkit-scrollbar {
    width: 12px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(77, 77, 77);

}


::-webkit-scrollbar-track {
    background-color: #e9feff;
}


.welcome {
    text-align: center;
}

.App {
    padding: 0cm 0cm 0cm 0cm;
    scroll-behavior: smooth;
    min-height: 100vh;
}

html {
    // background-color: #e9feff;
    scroll-behavior: smooth;
}


.menuImg {
    width: .8cm;
    display: none;
    cursor: pointer;
}

.name {
    font-family: Madimi One;
    font-size: 60px;
    line-height: 1.75cm;

    text-align: center;
    // font-weight: 600;
    color: #08a5a8;
}

.buttons {
    margin-top: .5cm;
    display: flex;
    justify-content: center;
    gap: .35cm;
}

.helpline {
    padding: .35cm 1.5cm;
    // width: 100%;
    border-radius: 1000px;
    background-color: #08a5a8;
    border: none;
    color: #e9feff;
    // font-size: 18px;
    letter-spacing: .01cm;
    cursor: pointer;
    border: 2.5px solid #08a5a8;
    transition: .3s ease;

}

.cb {
    padding: .3cm 1cm;
    width: calc(100% - 2cm);
    border-radius: 1000px;
    background-color: #08a5a8;
    border: none;
    text-align: center;
    color: #e9feff;
    font-size: 16px;
    letter-spacing: .01cm;
    cursor: pointer;
    border: 2.5px solid #08a5a8;
    transition: .3s ease;
}

.sb:hover {
    background-color: transparent;
    color: black;
}

.ab {
    background-color: transparent;
    color: black;

    &:Hover {
        background-color: #08a5a8;
        color: #e9feff;
    }
}

.hp {
    &:hover {
        background-color: transparent;
    }
}

.card-buttons {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    margin-top: .5cm;
    width: 100%;
    gap: .3cm;
}

.donate {
    background-color: transparent;
    // color: black;
    border: 2.5px solid #08a5a8;

    &:hover {
        background-color: #08a5a8;
    }
}

.upper-left {
    width: 12cm;
    display: flex;
    flex-direction: column;
    gap: .1cm;
    // border: thin solid white;
    align-items: center;
}

.upper-right {
    width: 11cm;
    flex-shrink: 0;
}


.yt-video {
    width: 100%;
    aspect-ratio: 16/9;
    border: none;
    border-radius: 10px;
}

.welcome {
    color: #e9feff;
    font-size: 20px;

}

.main-card {
    width: 9cm;
    height: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #c4fcff;

}

.head-text {
    font-size: 32px;
    text-align: center;
    // font-weight: 600;
    font-family: dancing script;
    color: #e9feff;
}

.lower-div {
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: 1cm;

    margin-top: 1cm;
}

.rld {
    padding: 0cm 10vw;
}

.sbb {
    margin-top: .5cm;
}


.resident-text {
    text-align: center;
    color: #e9feff;
}

.home-lower {
    padding: 0cm 10vw;
}

.card-upper {

    padding: .2cm .5cm 0.1cm .5cm;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-card-box {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: .5cm;
}

.card-lower {
    display: flex;
    flex-direction: column;
    width: calc(100% - 1cm);
    border-radius: 0px 0px 10px 10px;
    align-items: center;
    justify-content: space-between;
    background-color: #e9feff;
    padding: .2cm .5cm .25cm .5cm;
    height: 100%;
}

.card-lower-head {
    font-size: 24px;
    font-family: Madimi One;
    color: #08a5a8;
    text-align: center;
}

.wwd {
    font-size: 30px;
}

.cardImg {
    width: 5cm;
    border-radius: 56% 44% 46% 54% / 41% 44% 56% 59%;

}

.lower-desc {
    text-align: center;
}

.home-upper {

    position: relative;


    &::before {
        position: absolute;
        content: "";
        inset: 0;
        background-image: url("backh.jpeg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        filter: brightness(40%);
    }
}

.sdd {
    margin-top: 2cm;
}

.home-upper-sub {
    padding: 2cm 10vw 3cm 10vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Nav {
    padding: .5cm 10vw 0cm 10vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo-box {
    width: 2cm;
}

.logo {
    width: 100%;
}

.menu {
    display: flex;
    align-items: center;
    gap: .3cm;
    justify-content: flex-end;

}

.menu-item {
    color: #e9feff;
}

.contact-left {
    // border: 2px solid black;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5cm;

}

.contact-form {
    width: 100%;
    // max-width: 18cm;
    height: auto;
    // border: 1px solid black;
    display: flex;
    flex-direction: column;
    gap: .3cm;
}

.contact-box {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    gap: .5cm;
}

.or {
    text-align: center;
    color: rgb(128, 128, 128);
    font-size: 14px;
}

textarea {
    height: 2.5cm;
}

.contact-inp {
    border: 2px solid #08a5a88d;
    padding: .25cm .3cm;
    border-radius: 10px;
    font-size: 16px;

    &:focus {
        border: 2px solid #08a5a8;
        outline: none;
    }
}

.submit-inp {
    border: 2px solid #08a5a8;
    background-color: #08a5a8;
    padding: .3cm .6cm;
    border-radius: 10px;
    color: #e9feff;
    font-size: 16px;
}

.map {
    border: 1px solid #08a5a8ab;
    // border: none;
    border-radius: 10px;
}

.con-dets {
    display: flex;
    flex-direction: column;
    gap: .3cm;
    align-items: center;
}

.con-det {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .15cm;
    cursor: pointer;
    padding: .15cm .3cm;
    border: 1px solid transparent;
    border-radius: 5px;
    width: fit-content;
    transition: .3s;


    &:hover {

        border: 1px solid #08a5a8;

    }


}

.scrollup {
    position: fixed;
    display: none;
    z-index: 1000;
    width: .5cm;
    // height: 1cm;
    border-radius: 1000px;
    background-color: #08a5a8;
    cursor: pointer;
    bottom: 0;
    margin: 1cm;
    right: 0;
    padding: .15cm .3cm .2cm .3cm;
}

.up {
    width: 100%;
    height: auto;
}

.det-img {
    width: .4cm;
    height: auto;
}

.phi {
    transform: rotate(15deg);
}

.lbh {
    color: black;
}

.Residents {
    padding: 0cm 10vw;
}


.donation-methods {
    margin-top: 1cm;
    display: flex;
    flex-direction: column;
    gap: 1cm;
}

.donation-method-head {
    font-size: 20px;
    font-weight: 600;
}

.redirect {
    width: .4cm;
}

.wishlist-text {
    color: #e9feff;
}

.key-donate {
    margin-left: 1cm;
}

.imp-head {
    font-size: 18px;
    font-style: italic;
    font-weight: 600;
}

.wishlist-link-box {
    border: 1px solid black;
    margin-top: .3cm;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: .15cm;
    background-color: #08a5a8;
    border: 2px solid #08a5a8;
    padding: .3cm 1cm;
    border-radius: 1000px;

}

.qr-codes {
    margin-top: .3cm;
}

.pform {
    margin-top: .5cm;
    width: 12cm;
}


.sdform {
    // margin-top: .5cm;
    width: 18cm;
}

.error-text {
    color: red;
    font-size: 14px;
}

.err {
    border: 2px solid red;
}

.daOpen {
    transition: .3s;
    transform: rotate(180deg);
}


.sbtext {
    text-align: left;
    color: black;
}


.dropdown {
    position: relative;
    transition: hover .3s;


    // display: inline-block;
}

.drpbox {
    display: flex;
    align-items: center;
    gap: .7cm;
    justify-content: space-between;
    padding: .3cm .4cm;
    cursor: pointer;



    // padding: 16px;
    transition: .3s;
    border-radius: 10px;
    // background-color: rgb(204, 149, 255);
    background-color: transparent;
    border: 2px solid #08a5a88d;

    &:focus {
        border: 2px solid #08a5a8;
        // outline: none/;
    }



}

/* Dropdown Content (Hidden by Default) */
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: block;

    position: absolute;
    height: fit-content;

    overflow-y: auto;


    transition: 0.3s ease;
    border-radius: 5px;
    background-color: #f9f9f9;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;


}

/* Show the dropdown menu on hover */



/* Links inside the dropdown */
.dropdown-content p {
    color: black;
    padding: .2cm .2cm;
    text-decoration: none;
    display: block;
    cursor: pointer;
    border-bottom: 1px solid rgb(206, 206, 206);
    transition: .3s ease;
    font-size: 15px;



    &:hover {
        background-color: rgb(220, 220, 220);
    }

}

.da {
    width: .3cm;
    height: auto;
    transition: .3s ease;
}

.adhd {
    display: flex;
    flex-direction: column;
    gap: .5cm;
    padding: 1cm 10vw 3cm 10vw;
    // border: 1px solid red;
}

.adhm {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        inset: 0;
        background-image: url("adopt.jpg");
        z-index: -1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(35%);
    }
}

.rahm {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        inset: 0;
        background-image: url("residentsd.jpeg");
        z-index: -1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(30%);
    }
}

.dphm {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        inset: 0;
        background-image: url("donate.jpg");
        z-index: -1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(37%);
    }
}

.sphm {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        inset: 0;
        background-image: url("surrender.jpg");
        z-index: -1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(35%);
    }
}

.fphm {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        inset: 0;
        background-image: url("founders.jpg");
        z-index: -1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(35%);
    }
}

.shphm {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        inset: 0;
        background-image: url("shelter.jpeg");
        z-index: -1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(40%);
    }
}

.htext {
    color: black;
}

.Footer {
    margin-top: 2cm;
    background-color: #08a5a8;

    padding: 0.3cm 10vw 0cm 10vw;

}

.footer-reach {
    margin-top: .5cm;
    font-size: 17px;
    font-weight: 600;
    color: #e9feff;
}

.footer-menu {
    display: flex;
    flex-direction: column;
    margin-top: .2cm;
}

.logoText {
    font-size: 30px;
    color: #e9feff;
    letter-spacing: .03cm;
    font-family: Madimi One;
    cursor: pointer;
}

.footer-head-sub {
    font-size: 17px;
    font-weight: 600;
    color: #e9feff;
}

.footer-icons {
    display: flex;
    margin-top: .1cm;
    align-items: center;
    column-gap: .15cm;
}

.footer-email {
    // font-weight: 600;
    color: #e9feff;
    // padding: .15cm .3cm;
    border-bottom: 1px solid transparent;
    // border-radius: 5px;
    width: fit-content;
    cursor: pointer;
    transition: .3s;


    &:hover {

        border-bottom: 1px solid #e9feff;

    }
}

.footer-icon {
    width: .7cm;
    height: auto;
}


.footer-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding-bottom: .3cm;
    gap: 1cm;
    border-bottom: thin solid #12d3d66b;


}

.second-footer {
    display: flex;

    column-gap: 1cm;
}


.company-footer {
    width: 15cm;

}

.footer-logo {
    font-family: Kalam;
    font-size: 30px;
}

.footer-about {
    font-size: 15px;
    margin-top: .1cm;
    // color: rgb(48, 48, 48);
    color: #e9feff;


}

.footer-email {
    a {
        color: #e9feff;
        word-break: break-all;
    }
}


.footer-lower-txt {
    font-size: 15px;
    color: rgb(48, 48, 48);


}

.lower-footer-icons {
    display: flex;
    align-items: center;
    column-gap: .13cm;
}

.lower-footer-icon {
    width: .6cm;
    height: auto;
    cursor: pointer;
    transition: .2s all;

    &:hover {
        margin-top: -0.07cm;
    }
}

.lower-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: .3cm;
    padding: .2cm 0cm .2cm 0cm;
}

.footer-menuItem {
    font-size: 15px;
    cursor: pointer;
    // color: rgb(48, 48, 48);
    color: #e9feff;



}

.welcome-boxa {
    // border: 1px solid black;
    margin-bottom: .5cm;
}

.content-box {
    display: flex;
    flex-direction: column;
    gap: 1cm;
}

.bt {
    font-weight: 600;
}

.welcomea {
    font-size: 26px;
    font-weight: 600;
}

.head-cats {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .3cm;
    margin-top: .5cm;
    flex-wrap: wrap;
}

.head-cat {
    width: 4cm;
    text-align: center;
    border-radius: 1000px;
    border: 2px solid #08a5a8;
    padding: .3cm .6cm;
    text-decoration: none;
    color: black;
    background-color: #e9feff;
    cursor: pointer;
    transition: .25s ease-in-out;
    // font-size: 18px;

    &:hover {
        background-color: #08a5a8;
        color: #e9feff;

    }
}

.det-text {
    font-size: 14px;
    word-break: break-all;
}

.active-cat {
    background-color: #08a5a8;
    color: #e9feff;
}

.MainSec {
    // border: 1px solid black;
    margin-top: 1cm;
}

.shel-imgs-div {
    // margin-top: 1.5cm;
}


.images-shel {
    column-count: 5;
    column-gap: .3cm;
    margin-top: .5CM;
    // display: flex;
    // flex-wrap: wrap;
    // gap: .3cm;
    // justify-content: center;
}

.image-shel {
    width: 100%;
    height: auto;
    margin-top: .1cm;
    border-radius: 10px;
    cursor: pointer;
    transition: .2s ease;

    &:hover {
        filter: brightness(75%);
    }
}



.det-head {
    font-size: 30px;
    color: #08a5a8;
    font-family: Madimi One;

}

.shel-main-dets {
    display: flex;
    flex-direction: column;
    gap: 1.1cm;
    align-items: center;
}


.shel-dets {
    display: flex;
    margin-top: .2cm;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1cm;
}

.shel-det-img {
    width: 13cm;
    height: auto;
    border-radius: 10px;
}

.PictureDisplay {
    position: fixed;
    inset: 0;
    backdrop-filter: blur(10px);
    z-index: 100;
    padding: 1cm 5vw;
    display: none;
    align-items: center;
    justify-content: center;
}

.pdbox {
    width: fit-content;
    height: 100%;
    // height: fit-content;
    border: 2px solid #08a5a8;
    background-color: #08a5a8;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    // overflow: auto;
    padding: .3cm;
}


.pdbox-head {
    display: flex;
    justify-content: flex-end;
    border-bottom: thin solid #e9feff5d;
    padding: 0cm 0cm .2cm 0cm;
}

.pdbox-img-box {
    width: 15cm;
    height: calc(100% - .7cm);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: .2cm;
    overflow: auto;
    // margin-bottom: .2cm;

}

.pdbox-img {
    // max-width: auto;
    width: 100%;
    height: auto;
    max-height: auto;
}

.close {
    width: .4cm;
    height: auto;
    cursor: pointer;
}




.overlayDiv {
    position: fixed;
    inset: 0;
    overflow-y: auto;
    backdrop-filter: blur(40px);
    // background-color: rgba(255, 255, 255, 0.433);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2cm .5cm;
    display: none;
}

.payment-sec {

    width: 10cm;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: .3cm;
    margin-top: .4cm;




}


.pay-inp {
    padding: .3cm .4cm;
    font-size: 16px;
    border: 2px solid #08a5a8;
    border-radius: 5px;

    &:focus {
        outline: none;
        border: 2px solid blueviolet;
    }
}

.af {
    background-color: transparent;
}

.wepcpdf {
    width: 100%;
    height: auto;
}

.image-div{
    // border: 1px solid black;
    width: 100%;
    margin: 1cm 0cm 2cm 0cm;
    display: flex;
    justify-content: center;
}

.jan-img{
    width: 70%;
    height: auto;
}


.pay-button {
    background-color: #08a5a8;
    color: #e9feff;
    border: 2px solid #08a5a8;

    padding: .3cm .5cm;

    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: .3s ease;

    &:hover {
        background-color: transparent;
        color: black;
    }

}


.overlayAdopt {
    width: fit-content;
    padding: .5cm;
    border-radius: 10px;
    height: auto;
    background-color: #e9feff;
    display: flex;
    flex-direction: column;
    gap: .2cm;
    border: 1px solid #a2faff;
}

.head-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .3cm;
}

.af {
    background-color: transparent;
}

.cross {
    width: .4cm;
    height: auto;
    cursor: pointer;
}

.adopt-butns-div {
    display: flex;
    gap: .3cm;
}

.success {
    width: 2.5cm;
    height: auto;
}

.thanks-box {
    align-items: center;
    gap: .1cm;
    margin-top: 0cm;
}

.thanksText {
    font-size: 20px;
    text-align: center;
    line-height: .65cm;
}

#thanksBox {
    display: none;
}

.teamText {
    font-size: 16px;
    text-align: center;
}

.okay {
    padding: .2cm .6cm;
    background-color: blueviolet;
    color: white;
    font-size: 16px;
    border: 2px solid blueviolet;
    border-radius: 50px;
    margin-top: .5cm;
    cursor: pointer;
    transition: .3s;

    &:hover {
        background-color: transparent;
        color: black;
    }
}

.spd {
    width: fit-content;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    transition: hover .3s;


    // display: inline-block;
}

.drpbox {
    display: flex;
    align-items: center;
    gap: .7cm;
    justify-content: space-between;
    padding: .3cm .4cm;
    cursor: pointer;



    // padding: 16px;
    transition: .3s;
    border-radius: 5px 5px 5px 5px;
    // background-color: rgb(204, 149, 255);
    background-color: transparent;
    border: 2px solid #08a5a8;



}

/* Dropdown Content (Hidden by Default) */
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: block;

    position: absolute;
    height: fit-content;

    overflow-y: auto;


    transition: 0.3s ease;
    border-radius: 5px;
    background-color: #f9f9f9;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;


}

/* Show the dropdown menu on hover */



/* Links inside the dropdown */
.dropdown-content p {
    color: black;
    padding: .2cm .2cm;
    text-decoration: none;
    display: block;
    cursor: pointer;
    border-bottom: 1px solid rgb(206, 206, 206);
    transition: .3s ease;
    font-size: 15px;



    &:hover {
        background-color: rgb(220, 220, 220);
    }

}

.da {
    width: .3cm;
    height: auto;
    transition: .3s ease;
}

.errorText {
    color: red;
}

#errorBosSponsor {
    display: none;
}


#overlayNav {
    display: none;
}


.overlayNav {
    position: fixed;
    z-index: 100;
    display: none;
    backdrop-filter: blur(10px);
    inset: 0;
    display: flex;
    justify-content: flex-end;
}

.overlayNavBox {
    width: 10cm;
    height: 100%;
    background-color: #08a5a8;
    overflow-y: auto;
    padding: 1cm;

}

.overlayMenu {
    display: flex;
    flex-direction: column;
    gap: .3cm;
}

.overlayMenuItem {
    padding: .3cm;
    border: 2px solid #e9feff;
    color: #e9feff;
    cursor: pointer;
    transition: .3s;
    // width: fit-content;
    border-radius: 1000px;
    text-align: center;

    &:hover {
        background-color: #e9feff;
        color: black;
    }

}

.closeMenu {
    border: 2px solid red;
    background-color: red;
    cursor: pointer;

    &:hover {
        background-color: #e9feff;
        color: red;
    }
}











@media only screen and (max-width : 1400px) {
    .name {
        font-size: 50px;
        line-height: 1.5cm;
    }

    .upper-right {
        width: 10cm;
        flex-shrink: 0;
    }

    .Nav {
        padding: .3cm 5vw 0cm 5vw;
    }

    .home-upper-sub,
    .adhd {
        padding: 2cm 5vw 3cm 5vw;
    }

    .home-lower,
    .rld {
        padding: 0cm 5vw;
    }

    .det-head {
        font-size: 28px;
    }

    .shel-det-img {
        width: 12cm;
    }

    .shel-dets {
        gap: .7cm;
    }

    .shel-det-text {
        font-size: 15px;
    }

    .logo-box {
        width: 1.5cm;
    }

    .menu-item {
        font-size: 15px;
    }

    .head-text {
        font-size: 30px;
    }

    .main-card {
        width: 8cm;
    }

    // .cardImg {
    //     width: 4cm;
    // }

    .card-lower-head {
        font-size: 22px;
    }

    .lower-desc {
        font-size: 15px;
    }

    .wwd {
        font-size: 27px;
    }

    .cb {
        padding: .25cm .7cm;
        font-size: 15px;
    }

    .contact-inp {
        font-size: 15px;
    }

    .logoText {
        font-size: 28px;
    }




}

@media only screen and (max-width : 1200px) {


    .det-head {
        font-size: 27px;
    }

    .shel-det-img {
        width: 11cm;
    }

    .shel-dets {
        gap: .5cm;
    }

    .shel-det-text {
        font-size: 14px;
    }


    .Footer {
        padding: .3cm 5vw 0cm 5vw;
    }

    .name {
        font-size: 45px;
        line-height: 1.3cm;
    }

    .welcome {
        font-size: 18px;
    }

    .head-text {
        font-size: 28px;
    }

    .upper-left {
        gap: .3cm;
    }

    .buttons {
        margin-top: .3cm;
    }

    .upper-right {
        width: 9.5cm;
    }

    .helpline {
        padding: .3cm 1.2cm;
    }

    .Nav {
        padding: .3cm 5vw 0cm 5vw;
    }

    .home-upper-sub,
    .adhd {
        padding: 1.5cm 5vw 2.5cm 5vw;
    }

    .home-lower {
        padding: 0cm 5vw;
    }

    .logo-box {
        width: 1.5cm;
    }

    .menu-item {
        font-size: 15px;
    }



    .main-card {
        width: 7.5cm;
    }

    .card-lower {
        width: calc(100% - .6cm);
        padding: .2cm .3cm .25cm .3cm;
    }



    .card-upper {
        padding: .2cm .3cm 0.1cm .3cm;
    }

    .card-lower-head {
        font-size: 20px;
    }

    .lower-desc {
        font-size: 15px;
    }

    .wwd {
        font-size: 25px;
    }

    .cb {
        padding: .25cm .7cm;
        font-size: 15px;
    }

    .contact-inp {
        font-size: 15px;
    }

    .logoText {
        font-size: 28px;
    }



    .map {
        width: 13cm;
        height: 10cm;
    }

    .resident-text {
        font-size: 15px;
    }

    .adhd {
        gap: .3cm;
    }

    .head-cat {
        font-size: 15px;
        padding: .25cm .5cm;
        width: 3.5cm;
    }

    .donation-method-head {
        font-size: 18px;
    }

    .dm-det {
        font-size: 15px;
        margin-top: .2cm;
    }

    .bank-dets {
        font-size: 14px;
    }

    .qr-code {
        width: 4cm;
        height: auto;
    }

    .donation-methods {
        margin-top: .7cm;
        display: flex;
        flex-direction: column;
        gap: .7cm;
    }

    .keyd {
        font-size: 15px;
    }

    .imp-head {
        font-size: 17px;
    }
}








@media only screen and (max-width : 1100px) {


    .det-head {
        font-size: 26px;
    }

    .shel-det-img {
        width: 10cm;
    }

    .shel-dets {
        gap: .5cm;
        margin-top: .1cm;
    }

    .shel-det-text {
        font-size: 14px;
    }



    .name {
        font-size: 40px;
        line-height: 1.2cm;
    }

    .welcome {
        font-size: 18px;
    }

    .upper-left {
        gap: .3cm;
    }

    .buttons {
        margin-top: .2cm;
    }

    .upper-right {
        width: 9cm;
    }

    .helpline {
        padding: .25cm 1cm;
    }

    .Nav {
        padding: .3cm 5vw 0cm 5vw;
    }

    .home-upper-sub,
    .adhd {
        padding: 1.2cm 5vw 2.2cm 5vw;
    }

    .home-lower {
        padding: 0cm 5vw;
    }

    .logo-box {
        width: 1.5cm;
    }

    .menu-item {
        font-size: 15px;
    }



    .main-card {
        width: 7cm;
    }

    .card-lower {
        width: calc(100% - .6cm);
        padding: .2cm .3cm .25cm .3cm;
    }



    .card-upper {
        padding: .2cm .3cm 0.1cm .3cm;
    }

    .card-lower-head {
        font-size: 18px;
    }

    .lower-desc {
        font-size: 14px;
    }

    .wwd {
        font-size: 23px;
    }

    .cb {
        padding: .2cm .5cm;
        font-size: 15px;

    }

    .contact-inp {
        font-size: 15px;
        padding: .25cm;
    }





    .map {
        width: 12cm;
        height: 9cm;
    }

    .resident-text {
        font-size: 15px;
    }

    .adhd {
        gap: .3cm;
    }

    .head-cat {
        font-size: 15px;
        padding: .2cm .5cm;
    }

    .donation-method-head {
        font-size: 18px;
    }

    .dm-det {
        font-size: 15px;
        margin-top: .2cm;
    }

    .bank-dets {
        font-size: 14px;
    }

    .qr-code {
        width: 4cm;
        height: auto;
    }

    .donation-methods {
        margin-top: .7cm;
        display: flex;
        flex-direction: column;
        gap: .7cm;
    }

    .keyd {
        font-size: 15px;
    }

    .imp-head {
        font-size: 17px;
    }

    .card-buttons {
        margin-top: .3cm;
    }

    .images-shel {
        column-count: 4;
    }
}

@media only screen and (max-width : 1000px) {


    .name {
        font-size: 37px;
        line-height: 1.15cm;
    }

    .welcome {
        font-size: 18px;
    }

    .upper-left {
        gap: .3cm;
        width: 11cm;
    }

    .buttons {
        margin-top: .2cm;
    }

    .upper-right {
        width: 8cm;
    }

    .helpline {
        padding: .25cm 1cm;
    }

    .Nav {
        padding: .3cm 5vw 0cm 5vw;
    }

    .home-upper-sub,
    .adhd {
        padding: 1.2cm 5vw 2.2cm 5vw;
    }

    .home-lower {
        padding: 0cm 5vw;
    }

    .logo-box {
        width: 1.5cm;
    }

    .menu-item {
        font-size: 15px;
    }



    .main-card {
        width: 6.5cm;
    }

    .card-lower {
        width: calc(100% - .6cm);
        padding: .2cm .3cm .25cm .3cm;
    }



    .card-upper {
        padding: .2cm .3cm 0.1cm .3cm;
    }

    .card-lower-head {
        font-size: 18px;
    }

    .lower-desc {
        font-size: 14px;
    }

    .wwd {
        font-size: 22px;
    }

    .cb {
        padding: .25cm .5cm;
        font-size: 15px;

    }

    .contact-inp {
        font-size: 15px;
        padding: .25cm;
    }





    .map {
        width: 11cm;
        height: 9cm;
    }

    .resident-text {
        font-size: 15px;
    }

    .adhd {
        gap: .3cm;
    }

    .head-cat {
        font-size: 15px;
        padding: .2cm .5cm;

    }

    .donation-method-head {
        font-size: 18px;
    }

    .dm-det {
        font-size: 15px;
        margin-top: .2cm;
    }

    .bank-dets {
        font-size: 14px;
    }

    .qr-code {
        width: 4cm;
        height: auto;
    }

    .donation-methods {
        margin-top: .7cm;
        display: flex;
        flex-direction: column;
        gap: .7cm;
    }

    .keyd {
        font-size: 15px;
    }

    .imp-head {
        font-size: 16px;
    }

    .card-buttons {
        margin-top: .3cm;
    }

    .images-shel {
        column-count: 4;
    }

    .wishlist-text {
        font-size: 15px;
    }

    .redirect {
        width: .35cm;
    }


}

@media only screen and (max-width : 900px) {




    .det-head {
        font-size: 26px;
    }

    .shel-det-img {
        width: 9cm;
    }

    .shel-dets {
        gap: .5cm;
        margin-top: .1cm;
    }

    .shel-det-text {
        font-size: 14px;
    }






    .name {
        font-size: 35px;
        line-height: 1.15cm;
    }

    .welcome {
        font-size: 17px;
    }


    .head-text {
        font-size: 26px;
    }

    .upper-left {
        gap: .3cm;
        width: 10cm;
    }

    .buttons {
        margin-top: .2cm;
    }

    .upper-right {
        width: 8cm;
    }

    .helpline {
        padding: .25cm 1cm;
    }

    .Nav {
        padding: .3cm 5vw 0cm 5vw;
    }

    .home-upper-sub,
    .adhd {
        padding: 1.2cm 5vw 2.2cm 5vw;
    }

    .home-lower {
        padding: 0cm 5vw;
    }

    .logo-box {
        width: 1.5cm;
    }

    .menu-item {
        font-size: 15px;
    }



    .main-card {
        width: 6cm;
    }

    .card-lower {
        width: calc(100% - .6cm);
        padding: .2cm .3cm .25cm .3cm;
    }



    .card-upper {
        padding: .2cm .3cm 0.1cm .3cm;
    }

    .card-lower-head {
        font-size: 18px;
    }

    .lower-desc {
        font-size: 14px;
    }

    .wwd {
        font-size: 22px;
    }

    .cb {
        padding: .2cm .4cm;
        font-size: 15px;

    }

    .contact-inp {
        font-size: 15px;
        padding: .25cm;
    }


    .lower-div {
        margin-top: .7cm;
    }



    .map {
        width: 10cm;
        height: 7cm;
    }

    .resident-text {
        font-size: 15px;
    }

    .adhd {
        gap: .3cm;
    }

    .head-cat {
        font-size: 15px;
        padding: .2cm .5cm;
    }

    .donation-method-head {
        font-size: 18px;
    }

    .dm-det {
        font-size: 15px;
        margin-top: .2cm;
    }

    .bank-dets {
        font-size: 14px;
    }

    .qr-code {
        width: 4cm;
        height: auto;
    }

    .donation-methods {
        margin-top: .7cm;
        display: flex;
        flex-direction: column;
        gap: .7cm;
    }

    .keyd {
        font-size: 15px;
    }

    .imp-head {
        font-size: 16px;
    }

    .card-buttons {
        margin-top: .3cm;
    }

    .images-shel {
        column-count: 4;
    }

    .wishlist-text {
        font-size: 15px;
    }

    .redirect {
        width: .35cm;
    }




}


@media only screen and (max-width : 820px) {
    .second-footer {
        flex-direction: column;
        row-gap: .5cm;
    }

    .name {
        font-size: 50px;
        line-height: 1.5cm;
    }

    .welcome {
        font-size: 18px;
    }

    .upper-left {
        gap: .3cm;
        width: 100%;
    }

    .buttons {
        margin-top: .2cm;
    }

    .upper-right {
        width: 10cm;
    }

    .helpline {
        padding: .3cm 2cm;
    }

    .Nav {
        padding: .3cm 5vw 0cm 5vw;
    }

    .home-upper-sub {
        flex-direction: column;
        row-gap: 1.5cm;
    }


    .home-upper-sub,
    .adhd {
        padding: 1.5cm 5vw 1.5cm 5vw;
    }

    .home-lower {
        padding: 0cm 5vw;
    }

    .logo-box {
        width: 1.5cm;
    }

    .menu-item {
        font-size: 15px;
    }



    .main-card {
        width: 6cm;
    }

    .card-lower {
        width: calc(100% - .6cm);
        padding: .2cm .3cm .25cm .3cm;
    }



    .card-upper {
        padding: .2cm .3cm 0.1cm .3cm;
    }

    .card-lower-head {
        font-size: 18px;
    }

    .lower-desc {
        font-size: 14px;
    }

    .wwd {
        font-size: 22px;
    }

    .cb {
        padding: .2cm .4cm;
        font-size: 15px;

    }

    .contact-inp {
        font-size: 15px;
        padding: .25cm;
    }


    .lower-div {
        margin-top: .7cm;
    }



    .map {
        width: 8cm;
        height: 7cm;
    }

    .resident-text {
        font-size: 15px;
    }

    .adhd {
        gap: .3cm;
    }

    .head-cat {
        font-size: 15px;
        padding: .2cm .5cm;
    }

    .donation-method-head {
        font-size: 18px;
    }

    .dm-det {
        font-size: 15px;
        margin-top: .2cm;
    }

    .bank-dets {
        font-size: 14px;
    }

    .qr-code {
        width: 4cm;
        height: auto;
    }

    .donation-methods {
        margin-top: .7cm;
        display: flex;
        flex-direction: column;
        gap: .7cm;
    }

    .keyd {
        font-size: 15px;
    }

    .imp-head {
        font-size: 16px;
    }

    .card-buttons {
        margin-top: .3cm;
    }

    .images-shel {
        column-count: 4;
    }

    .wishlist-text {
        font-size: 15px;
    }

    .redirect {
        width: .35cm;
    }

    .sdform {
        width: 100%;
    }
}


@media only screen and (max-width : 800px) {
    .det-head {
        font-size: 26px;
    }

    .shel-det-img {
        width: 8cm;
    }

    .shel-dets {
        gap: .5cm;
        margin-top: .1cm;
    }

    .shel-det-text {
        font-size: 14px;
    }
}


@media only screen and (max-width : 700px) {

    .pdbox {
        width: 100%;
    }

    .pdbox-img-box {
        width: 100%;
    }

    .det-head {
        font-size: 26px;
    }

    .shel-det-img {
        width: 10cm;
    }

    .shel-dets {
        gap: .5cm;
        flex-direction: column-reverse;
        margin-top: .3cm;
    }

    .shel-det-text {
        font-size: 14px;
    }

    .name {
        font-size: 40px;
        line-height: 1.25cm;
    }

    .welcome {
        font-size: 18px;
    }

    .contact-box {
        flex-direction: column;
    }

    .contact-left {
        justify-content: center;
        align-items: center;
    }

    .upper-left {
        gap: .3cm;
        width: 100%;
    }

    .buttons {
        margin-top: .2cm;
    }

    .upper-right {
        width: 10cm;
    }

    .helpline {
        padding: .3cm 2cm;
    }

    .Nav {
        padding: .3cm 5vw 0cm 5vw;
    }

    .home-upper-sub {
        flex-direction: column;
        row-gap: 1.5cm;
    }


    .home-upper-sub,
    .adhd {
        padding: 1.5cm 5vw 1.5cm 5vw;
    }

    .home-lower {
        padding: 0cm 5vw;
    }

    .logo-box {
        width: 1.5cm;
    }

    .menu-item {
        display: none;
    }



    .main-card {
        width: 6cm;
    }

    .card-lower {
        width: calc(100% - .6cm);
        padding: .2cm .3cm .25cm .3cm;
    }



    .card-upper {
        padding: .2cm .3cm 0.1cm .3cm;
    }

    .card-lower-head {
        font-size: 18px;
    }

    .lower-desc {
        font-size: 14px;
    }

    .wwd {
        font-size: 22px;
    }

    .cb {
        padding: .2cm .4cm;
        font-size: 15px;

    }

    .contact-inp {
        font-size: 15px;
        padding: .25cm;
    }


    .lower-div {
        margin-top: .7cm;
    }



    .contact-right {
        width: 100%;
    }

    .map {
        width: 100%;
        height: 7cm;
    }

    .resident-text {
        font-size: 15px;
    }

    .adhd {
        gap: .3cm;
    }

    .head-cat {
        font-size: 15px;
        padding: .2cm .5cm;
    }

    .donation-method-head {
        font-size: 18px;
    }

    .dm-det {
        font-size: 15px;
        margin-top: .2cm;
    }

    .bank-dets {
        font-size: 14px;
    }

    .qr-code {
        width: 4cm;
        height: auto;
    }

    .donation-methods {
        margin-top: .7cm;
        display: flex;
        flex-direction: column;
        gap: .7cm;
    }

    .keyd {
        font-size: 15px;
    }

    .imp-head {
        font-size: 16px;
    }

    .card-buttons {
        margin-top: .3cm;
    }

    .images-shel {
        column-count: 3;
    }

    .wishlist-text {
        font-size: 15px;
    }

    .redirect {
        width: .35cm;
    }


    .menuImg {
        display: flex;
    }
}


@media only screen and (max-width : 600px) {


    .footer-reach,
    .footer-head-sub {
        font-size: 16px;
    }

    .company-footer,
    .pform {
        width: 100%;
    }

    .menuImg {
        width: .7cm;
    }





    .footer-box {
        flex-direction: column;
        row-gap: .5cm;
        // align-items: center;
    }

    .second-footer {
        flex-direction: row;
    }




    .Footer {
        padding: 0.3cm 5vw 0cm 5vw;
    }



    .name {
        font-size: 38px;
        line-height: 1.2cm;
    }

    .welcome {
        font-size: 18px;
    }

    .contact-box {
        flex-direction: column;
    }

    .contact-left {
        justify-content: center;
        align-items: center;
    }

    .upper-left {
        gap: .3cm;
        width: 100%;
    }

    .buttons {
        margin-top: .2cm;
    }

    .upper-right {
        width: 100%;
    }

    .helpline {
        padding: .3cm 2cm;
    }

    .Nav {
        padding: .3cm 5vw 0cm 5vw;
    }

    .home-upper-sub {
        flex-direction: column;
        row-gap: 1.5cm;
    }


    .home-upper-sub,
    .adhd {
        padding: 1cm 5vw 1.5cm 5vw;
    }

    .home-lower {
        padding: 0cm 5vw;
    }

    .logo-box {
        width: 1.5cm;
    }





    .main-card {
        width: 6cm;
    }

    .card-lower {
        width: calc(100% - .6cm);
        padding: .2cm .3cm .25cm .3cm;
    }



    .card-upper {
        padding: .2cm .3cm 0.1cm .3cm;
    }

    .card-lower-head {
        font-size: 18px;
    }

    .lower-desc {
        font-size: 14px;
    }

    .overlayAdopt {
        width: 100%;
    }

    .wwd {
        font-size: 22px;
    }

    .cb {
        padding: .2cm .4cm;
        font-size: 15px;

    }

    .contact-inp {
        font-size: 15px;
        padding: .25cm;
    }

    .payment-sec {
        width: 100%;
    }

    .lower-div {
        margin-top: .7cm;
    }



    .contact-right {
        width: 100%;
    }

    .map {
        width: 100%;
        height: 7cm;
    }

    .resident-text {
        font-size: 14px;
    }

    .adhd {
        gap: .3cm;
    }

    .head-cat {
        font-size: 15px;
        padding: .2cm .5cm;
    }

    .donation-method-head {
        font-size: 18px;
    }

    .dm-det {
        font-size: 15px;
        margin-top: .2cm;
    }

    .bank-dets {
        font-size: 14px;
    }

    .qr-code {
        width: 4cm;
        height: auto;
    }

    .donation-methods {
        margin-top: .7cm;
        display: flex;
        flex-direction: column;
        gap: .7cm;
    }

    .keyd {
        font-size: 15px;
    }

    .imp-head {
        font-size: 16px;
    }

    .card-buttons {
        margin-top: .3cm;
    }

    .images-shel {
        column-count: 3;
    }

    .wishlist-text {
        font-size: 15px;
    }

    .redirect {
        width: .35cm;
    }



    .lower-div {
        gap: .5cm;
    }





    .head-cats {
        margin-top: 0cm;
    }

    .jan-img{
        width: 100%;
       
    }




}

@media only screen and (max-width : 540px) {



    .name {
        font-size: 35px;
        line-height: 1.15cm;
    }

    .welcome {
        font-size: 18px;
    }

    .contact-box {
        flex-direction: column;
    }

    .contact-left {
        justify-content: center;
        align-items: center;
    }

    .upper-left {
        gap: .3cm;
        width: 100%;
    }

    .buttons {
        margin-top: .2cm;
        flex-wrap: wrap;
    }

    .upper-right {
        width: 9cm;
    }

    .helpline {
        padding: .3cm 2cm;
    }

    .Nav {
        padding: .3cm 5vw 0cm 5vw;
    }

    .home-upper-sub {
        flex-direction: column;
        row-gap: 1.5cm;
    }


    .home-upper-sub,
    .adhd {
        padding: 1cm 5vw 1.5cm 5vw;
    }

    .home-lower {
        padding: 0cm 5vw;
    }

    .logo-box {
        width: 1.5cm;
    }





    .main-card {
        width: 100%;
    }

    .card-lower {
        width: calc(100% - .6cm);
        padding: .2cm .3cm .25cm .3cm;
    }



    .card-upper {
        padding: .2cm .3cm 0.1cm .3cm;
    }

    .card-lower-head {
        font-size: 18px;
    }

    .lower-desc {
        font-size: 14px;
    }

    .wwd {
        font-size: 24px;
    }

    .cb {
        padding: .2cm .4cm;
        font-size: 15px;
        width: calc(100% - 1cm);

    }

    .contact-inp {
        font-size: 15px;
        padding: .25cm;
    }


    .lower-div {
        margin-top: .7cm;
    }

    .sdd {
        margin-top: 1.2cm;
    }

    .contact-right {
        width: 100%;
    }

    .map {
        width: 100%;
        height: 7cm;
    }

    .resident-text {
        font-size: 14px;
    }

    .adhd {
        gap: .3cm;
    }

    .head-cat {
        font-size: 15px;
        padding: .2cm .5cm;
    }

    .donation-method-head {
        font-size: 18px;
    }

    .dm-det {
        font-size: 14px;
        margin-top: .2cm;
    }

    .bank-dets {
        font-size: 14px;
    }

    .qr-code {
        width: 4cm;
        height: auto;
    }

    .donation-methods {
        margin-top: .7cm;
        display: flex;
        flex-direction: column;
        gap: .7cm;
    }

    .keyd {
        font-size: 15px;
    }

    .imp-head {
        font-size: 16px;
    }

    .card-buttons {
        margin-top: .3cm;
        flex-direction: column;
    }

    .images-shel {
        column-count: 2;
    }

    .wishlist-text {
        font-size: 15px;
    }

    .redirect {
        width: .35cm;
    }



    .head-cats {
        margin-top: 0cm;
    }









}

@media only screen and (max-width : 500px) {


    .det-head {
        font-size: 26px;
        text-align: center;
    }

    .shel-det-img {
        width: 100%;
    }

    .shel-dets {
        gap: .5cm;
        flex-direction: column-reverse;
        margin-top: .3cm;
    }

    .shel-det-text {
        font-size: 14px;
    }


    .name {
        font-size: 45px;
        line-height: 1.4cm;
    }

    .welcome {
        font-size: 18px;
    }

    .contact-box {
        flex-direction: column;
    }

    .contact-left {
        justify-content: center;
        align-items: center;
    }

    .upper-left {
        gap: .3cm;
        width: 100%;
    }

    .buttons {
        margin-top: .2cm;
        flex-wrap: wrap;
    }

    .upper-right {
        width: 100%;
    }

    .helpline {
        padding: .3cm 2cm;
        width: 100%;
        text-align: center;
    }

    .Nav {
        padding: .3cm 5vw 0cm 5vw;
    }

    .home-upper-sub {
        flex-direction: column;
        row-gap: 1.5cm;
    }


    .home-upper-sub,
    .adhd {
        padding: 1cm 5vw 1.5cm 5vw;
    }

    .home-lower {
        padding: 0cm 5vw;
    }

    .logo-box {
        width: 1.5cm;
    }







    .card-lower {
        width: calc(100% - .6cm);
        padding: .2cm .3cm .25cm .3cm;
    }



    .card-upper {
        padding: .2cm .3cm 0.1cm .3cm;
    }

    .card-lower-head {
        font-size: 18px;
    }

    .lower-desc {
        font-size: 14px;
    }

    .wwd {
        font-size: 24px;
    }

    .cb {
        padding: .2cm .4cm;
        font-size: 15px;

    }

    .contact-inp {
        font-size: 15px;
        padding: .25cm;
    }


    .lower-div {
        margin-top: .7cm;
    }

    .sdd {
        margin-top: 1.2cm;
    }

    .contact-right {
        width: 100%;
    }

    .map {
        width: 100%;
        height: 7cm;
    }

    .resident-text {
        font-size: 14px;
    }

    .adhd {
        gap: .3cm;
    }

    .head-cat {
        font-size: 15px;
        padding: .2cm .5cm;
    }

    .donation-method-head {
        font-size: 18px;
    }

    .dm-det {
        font-size: 14px;
        margin-top: .2cm;
    }

    .bank-dets {
        font-size: 14px;
    }

    .qr-code {
        width: 4cm;
        height: auto;
    }

    .donation-methods {
        margin-top: .7cm;
        display: flex;
        flex-direction: column;
        gap: .7cm;
    }

    .keyd {
        font-size: 15px;
    }

    .imp-head {
        font-size: 16px;
    }

    .card-buttons {
        margin-top: .3cm;
    }

    .images-shel {
        column-count: 2;
    }

    .wishlist-text {
        font-size: 15px;
    }

    .redirect {
        width: .35cm;
    }



    .head-cats {
        margin-top: 0cm;
    }


}

@media only screen and (max-width : 470px) {



    // .name {
    //     font-size: 40px;
    //     line-height: 1.15cm;
    // }

    .welcome {
        font-size: 18px;
    }

    .contact-box {
        flex-direction: column;
    }

    .contact-left {
        justify-content: center;
        align-items: center;
    }

    .upper-left {
        gap: .3cm;
        width: 100%;
    }

    .buttons {
        margin-top: .2cm;
        flex-wrap: wrap;
    }

    .upper-right {
        width: 100%;
    }

    .helpline {
        padding: .3cm 2cm;
        width: 100%;
        text-align: center;
    }

    .Nav {
        padding: .3cm 5vw 0cm 5vw;
    }

    .home-upper-sub {
        flex-direction: column;
        row-gap: 1.5cm;
    }


    .home-upper-sub,
    .adhd {
        padding: 1cm 5vw 1.5cm 5vw;
    }

    .home-lower {
        padding: 0cm 5vw;
    }

    .logo-box {
        width: 1.5cm;
    }







    .card-lower {
        width: calc(100% - .6cm);
        padding: .2cm .3cm .25cm .3cm;
    }



    .card-upper {
        padding: .2cm .3cm 0.1cm .3cm;
    }

    .card-lower-head {
        font-size: 18px;
    }

    .lower-desc {
        font-size: 14px;
    }

    .wwd {
        font-size: 24px;
    }

    .cb {
        padding: .2cm .4cm;
        font-size: 15px;

    }

    .contact-inp {
        font-size: 15px;
        padding: .25cm;
    }


    .lower-div {
        margin-top: .7cm;
    }

    .sdd {
        margin-top: 1.2cm;
    }

    .contact-right {
        width: 100%;
    }

    .map {
        width: 100%;
        height: 7cm;
    }

    .resident-text {
        font-size: 14px;
    }

    .adhd {
        gap: .3cm;
    }

    .head-cat {
        font-size: 15px;
        padding: .2cm .5cm;
    }

    .donation-method-head {
        font-size: 18px;
    }

    .dm-det {
        font-size: 14px;
        margin-top: .2cm;
    }

    .bank-dets {
        font-size: 14px;
    }

    .qr-code {
        width: 4cm;
        height: auto;
    }

    .donation-methods {
        margin-top: .7cm;
        display: flex;
        flex-direction: column;
        gap: .7cm;
    }

    .keyd {
        font-size: 15px;
    }

    .imp-head {
        font-size: 16px;
    }

    .card-buttons {
        margin-top: .3cm;
    }

    .images-shel {
        column-count: 2;
    }

    .wishlist-text {
        font-size: 15px;
    }

    .redirect {
        width: .35cm;
    }


    .head-cats {
        margin-top: 0cm;
    }






}


@media only screen and (max-width : 410px) {


    .det-head {
        font-size: 24px;
    }







    // .name {
    //     font-size: 35px;
    //     line-height: 1.15cm;
    // }

    .welcome {
        font-size: 18px;
    }

    .contact-box {
        flex-direction: column;
    }

    .contact-left {
        justify-content: center;
        align-items: center;
    }

    .upper-left {
        gap: .3cm;
        width: 100%;
    }

    .buttons {
        margin-top: .2cm;
        flex-wrap: wrap;
    }

    .upper-right {
        width: 100%;
    }

    .helpline {
        padding: .3cm 2cm;
        width: 100%;
        text-align: center;
    }

    .Nav {
        padding: .3cm 5vw 0cm 5vw;
    }

    .home-upper-sub {
        flex-direction: column;
        row-gap: 1.5cm;
    }


    .home-upper-sub,
    .adhd {
        padding: 1cm 5vw 1.5cm 5vw;
    }

    .home-lower {
        padding: 0cm 5vw;
    }

    .logo-box {
        width: 1.5cm;
    }





    .main-card {
        width: 100%;
    }

    .card-lower {
        width: calc(100% - .6cm);
        padding: .2cm .3cm .25cm .3cm;
    }



    .card-upper {
        padding: .2cm .3cm 0.1cm .3cm;
    }

    .card-lower-head {
        font-size: 20px;
    }

    .lower-desc {
        font-size: 14px;
    }

    .wwd {
        font-size: 24px;
    }

    .cb {
        padding: .2cm .4cm;
        font-size: 15px;

    }

    .contact-inp {
        font-size: 15px;
        padding: .25cm;
    }


    .lower-div {
        margin-top: .7cm;
    }

    .sdd {
        margin-top: 1.2cm;
    }

    .contact-right {
        width: 100%;
    }

    .map {
        width: 100%;
        height: 7cm;
    }

    .resident-text {
        font-size: 14px;
    }

    .adhd {
        gap: .3cm;
    }

    .head-cat {
        font-size: 15px;
        padding: .2cm .5cm;

    }

    .donation-method-head {
        font-size: 16px;
    }

    .dm-det {
        font-size: 14px;
        margin-top: .2cm;
    }

    .bank-dets {
        font-size: 14px;
    }

    .qr-code {
        width: 4cm;
        height: auto;
    }

    .donation-methods {
        margin-top: .7cm;
        display: flex;
        flex-direction: column;
        gap: .7cm;
    }

    .keyd {
        font-size: 15px;
    }

    .imp-head {
        font-size: 16px;
    }

    .card-buttons {
        margin-top: .3cm;
    }

    .images-shel {
        column-count: 2;
    }

    .wishlist-text {
        font-size: 15px;
    }

    .redirect {
        width: .35cm;
    }



    .head-cats {
        margin-top: 0cm;
    }






}

@media only screen and (max-width : 300px) {


    .second-footer {
        flex-direction: column;
        column-gap: .5cm;
        // align-items: flex-start;
    }

    .name {
        font-size: 40px;
        line-height: 1.3cm;
    }

    .wwd {
        font-size: 22px;
    }

    .head-cat {
        width: auto;
    }




}